import * as React from 'react'
import { useParams, Link } from 'react-router-dom'

import useQuery from 'hooks/useQuery'
import { Modal } from 'components'
import FormLoader from 'renderer/FormLoader'
import { isEditingHCMSContent } from 'services/menu-items-service'
import formsHostnameConfiguration from 'formsHostnameConfiguration'
import { CP_HCMS_CONTENT_PREFILL_QUERY_PARAM } from '../cp-hcms-content/CPHCMSContentItemsList'

const isTrialExpired: boolean =
  formsHostnameConfiguration?.isTrialExpired || false

const formsAppId =
  typeof formsHostnameConfiguration?.formsAppId === 'number' &&
  !Number.isNaN(formsHostnameConfiguration.formsAppId)
    ? formsHostnameConfiguration.formsAppId
    : null

function FormScene() {
  const params = useParams<{ formId: string }>()
  const query = useQuery()

  if (isTrialExpired) {
    return (
      <Modal
        isOpen
        title="Your Trial Has Expired"
        className="cypress-expired-modal"
        titleClassName="cypress-expired-title"
        actions={
          <Link
            className="button ob-button is-primary"
            to="/"
            innerRef={(ref) => {
              ref?.focus()
            }}
          >
            Okay
          </Link>
        }
      >
        Please contact your Administrator if you have any questions.
      </Modal>
    )
  }

  const formId = parseInt(params.formId, 10)
  const formSlug = Number.isNaN(formId) ? params.formId : null
  const jobId = typeof query.jobId === 'string' ? query.jobId : null
  const externalId =
    typeof query.externalId === 'string' ? query.externalId : null
  const draftId = typeof query.draftId === 'string' ? query.draftId : null
  const preFillData =
    typeof query.preFillData === 'string' ? query.preFillData : null
  const cpHCMSPreFillDataId =
    typeof query[CP_HCMS_CONTENT_PREFILL_QUERY_PARAM] === 'string'
      ? query[CP_HCMS_CONTENT_PREFILL_QUERY_PARAM]
      : null
  const preFillFormDataId =
    typeof query.preFillFormDataId === 'string' ? query.preFillFormDataId : null
  const previousFormSubmissionApprovalId =
    typeof query.previousFormSubmissionApprovalId === 'string'
      ? query.previousFormSubmissionApprovalId
      : null
  const taskId = typeof query.taskId === 'string' ? query.taskId : null
  const taskActionId =
    typeof query.taskActionId === 'string' ? query.taskActionId : null
  const taskGroupInstanceId =
    typeof query.taskGroupInstanceId === 'string'
      ? query.taskGroupInstanceId
      : null
  const editedFromCpHcmsContentMenuItem = isEditingHCMSContent(
    formId,
    query.externalId,
  )
  const pendingTimestamp =
    typeof query.pendingTimestamp === 'string'
      ? query.pendingTimestamp
      : undefined

  return (
    <FormLoader
      formsAppId={formsAppId}
      formId={Number.isNaN(formId) ? null : formId}
      formSlug={formSlug}
      jobId={jobId}
      externalId={externalId}
      draftId={draftId}
      preFillData={preFillData}
      preFillFormDataId={preFillFormDataId}
      cpHCMSPreFillDataId={cpHCMSPreFillDataId}
      previousFormSubmissionApprovalId={previousFormSubmissionApprovalId}
      taskId={taskId}
      taskActionId={taskActionId}
      taskGroupInstanceId={taskGroupInstanceId}
      editedFromCpHcmsContentMenuItem={editedFromCpHcmsContentMenuItem}
      pendingTimestamp={pendingTimestamp}
    />
  )
}

export default React.memo(FormScene)
