import { useEffect } from 'react'
import { ServiceWorkerCacheUpdatedPayload } from './types'

export default function useServiceWorkerMessageEffect(
  handler: (payload: ServiceWorkerCacheUpdatedPayload) => void,
) {
  useEffect(() => {
    if(!navigator.serviceWorker) {
      return
    }
    const listener = (event: MessageEvent) => {
      console.log('Received message from service worker', event)
      if (event.data.type === 'CACHE_UPDATED') {
        const payload: ServiceWorkerCacheUpdatedPayload = event.data.payload
        handler(payload)
      }
    }

    //listen to events from the service worker
    navigator.serviceWorker.addEventListener('message', listener)

    return () => {
      navigator.serviceWorker.removeEventListener('message', listener)
    }
  }, [handler])
}
