import * as React from 'react'
import { Flip, toast } from 'react-toastify'
import { ScheduledTasksTypes } from '@oneblink/types'
import { draftService, scheduledTasksService } from '@oneblink/apps'
import { useHistory } from 'react-router-dom'
import ScheduledTaskUndoAlert, {
  HandleUndoCompleteTask,
} from 'scheduled-tasks/ScheduledTaskUndoAlert'
import { TaskResponse } from './useScheduledTasks'
import useIsShowingCompletedTasks from 'scheduled-tasks/useIsShowingCompletedTasks'
import formsApiService, { getFormIdentifier } from 'services/forms-api-service'

export type HandleAction = (
  action: ScheduledTasksTypes.TaskAction,
  scheduledTask: ScheduledTasksTypes.Task,
) => Promise<ScheduledTasksTypes.CompletedTask | void>

export default function useScheduledTaskActions({
  formsAppId,
  taskGroupInstanceId,
  filterDrafts,
  setTaskResponses,
}: {
  formsAppId: number
  taskGroupInstanceId?: string
  filterDrafts: ({
    filter,
    ifNoDrafts,
  }: {
    filter: (draft: draftService.LocalFormSubmissionDraft) => boolean
    ifNoDrafts: () => void
  }) => void
  setTaskResponses: React.Dispatch<React.SetStateAction<TaskResponse[]>>
}) {
  const history = useHistory()
  const [selectedTaskId, setSelectedTaskId] = React.useState<
    string | undefined
  >()

  const handleAnimatingTaskRemovalFinished = React.useCallback(
    (taskId: string) => {
      setTaskResponses((currentTaskResponses) => {
        return currentTaskResponses.map((taskResponse) => {
          if (taskResponse.task.taskId === taskId) {
            return {
              ...taskResponse,
              completedTask: taskResponse.isAnimatingTaskUndo
                ? undefined
                : taskResponse.completedTask,
              isAnimatingTaskCompletion: false,
              isAnimatingTaskUndo: false,
            }
          }
          return taskResponse
        })
      })
    },
    [setTaskResponses],
  )

  const isShowingCompletedTasks = useIsShowingCompletedTasks()

  const handleUndoCompleteTask = React.useCallback<HandleUndoCompleteTask>(
    (taskId) => {
      setTaskResponses((currentTaskResponses) => {
        return currentTaskResponses.map((taskResponse) => {
          if (taskResponse.task.taskId === taskId) {
            return {
              ...taskResponse,
              completedTask: isShowingCompletedTasks
                ? taskResponse.completedTask
                : undefined,
              isAnimatingTaskCompletion: false,
              isAnimatingTaskUndo: isShowingCompletedTasks,
            }
          }
          return taskResponse
        })
      })
    },
    [isShowingCompletedTasks, setTaskResponses],
  )

  const handleAction = React.useCallback<HandleAction>(
    async (action, scheduledTask) => {
      switch (action.type) {
        case 'CHANGE_STATUS': {
          switch (action.status) {
            case 'COMPLETE': {
              const completedTask = await scheduledTasksService.completeTask({
                formsAppId,
                taskId: scheduledTask.taskId,
                taskActionId: action.taskActionId,
                taskGroupInstanceId,
              })
              toast(
                ({ closeToast }) => (
                  <ScheduledTaskUndoAlert
                    completedTask={completedTask}
                    scheduledTask={scheduledTask}
                    onUndo={handleUndoCompleteTask}
                    onClose={closeToast}
                  />
                ),
                {
                  position: 'bottom-right',
                  autoClose: 10000,
                  hideProgressBar: true,
                  pauseOnHover: true,
                  closeOnClick: false,
                  transition: Flip,
                  closeButton: false,
                },
              )
              setTaskResponses((currentTaskResponses) => {
                return currentTaskResponses.map((taskResponse) => {
                  if (taskResponse.task.taskId === scheduledTask.taskId) {
                    return {
                      ...taskResponse,
                      completedTask,
                      isAnimatingTaskCompletion: true,
                    }
                  }
                  return taskResponse
                })
              })
              break
            }
          }
          break
        }
        case 'FORM': {
          const form = await formsApiService.getForm({
            formId: action.formId,
            formSlug: null,
            toCompleteTask: true,
            abortSignal: new AbortController().signal,
          })
          filterDrafts({
            filter: (draft) =>
              draft.formId === action.formId &&
              draft.taskId === scheduledTask.taskId &&
              draft.taskActionId === action.taskActionId &&
              draft.taskGroupInstanceId === taskGroupInstanceId,
            ifNoDrafts: () => {
              const url = new URL(
                `/forms/${getFormIdentifier(action.formId, [form])}`,
                window.location.origin,
              )
              url.searchParams.append('taskId', scheduledTask.taskId)
              url.searchParams.append('taskActionId', action.taskActionId)
              if (taskGroupInstanceId) {
                url.searchParams.append(
                  'taskGroupInstanceId',
                  taskGroupInstanceId,
                )
              }
              history.push(`${url.pathname}${url.search}`)
            },
          })

          break
        }
      }
    },
    [
      filterDrafts,
      formsAppId,
      handleUndoCompleteTask,
      history,
      setTaskResponses,
      taskGroupInstanceId,
    ],
  )

  const handleSelectTask = React.useCallback((taskId: string) => {
    setSelectedTaskId((currentSelectedTaskId) => {
      if (currentSelectedTaskId === taskId) {
        return
      } else {
        return taskId
      }
    })
  }, [])

  return {
    selectedTaskId,
    handleAction,
    handleSelectTask,
    handleAnimatingTaskRemovalFinished,
  }
}
