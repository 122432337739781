import * as React from 'react'
import { SwipeableList } from 'react-swipeable-list'
import 'react-swipeable-list/dist/styles.css'
import { DraftModal, MaterialIcon } from 'components'
import ScheduledTaskListItemContent from './ScheduledTaskListItemContent'
import CompletedScheduledTaskListItemContent from './ScheduledTaskListItemContent/CompletedScheduledTaskListItemContent'
import formsHostnameConfiguration from 'formsHostnameConfiguration'
import useFilteredDrafts from 'hooks/useFilteredDrafts'
import useScheduledTaskActions from 'hooks/useScheduledTaskActions'
import { TaskResponse, getDateString } from 'hooks/useScheduledTasks'
import ScheduledTaskTodayListItem from './ScheduledTaskTodayListItem'
import useIsShowingCompletedTasks from './useIsShowingCompletedTasks'
import { ViewTaskActionSubmissionState } from './ScheduledTasksListContainer'
import useFetchForms from 'hooks/useFetchForms'

const emptyFn = async () => {}

function ScheduledTasksList({
  currentDate,
  taskGroupInstanceId,
  taskResponses: unorderedTaskResponses,
  setTaskResponses,
  setViewTaskActionSubmissionState,
}: {
  currentDate: Date
  taskGroupInstanceId: string | undefined
  taskResponses: TaskResponse[]
  setTaskResponses: React.Dispatch<React.SetStateAction<TaskResponse[]>>
  setViewTaskActionSubmissionState: React.Dispatch<
    React.SetStateAction<ViewTaskActionSubmissionState>
  >
}) {
  const isShowingCompletedTasks = useIsShowingCompletedTasks()

  const {
    filteredDrafts,
    isSyncingAndReloadingDrafts,
    actions: { filterDrafts, unfilterDrafts },
  } = useFilteredDrafts()
  const { forms } = useFetchForms()

  const {
    selectedTaskId,
    handleAction,
    handleSelectTask,
    handleAnimatingTaskRemovalFinished,
  } = useScheduledTaskActions({
    setTaskResponses,
    taskGroupInstanceId,
    formsAppId: formsHostnameConfiguration?.formsAppId || NaN,
    filterDrafts,
  })

  const { isToday, isInThePast, isInTheFuture } = React.useMemo(() => {
    const currentDateString = getDateString(currentDate)
    const nowDateString = getDateString(new Date())
    const result = { isToday: false, isInThePast: false, isInTheFuture: false }
    if (currentDateString === nowDateString) {
      result.isToday = true
      return result
    }
    if (currentDateString < nowDateString) {
      result.isInThePast = true
      return result
    }
    if (currentDateString > nowDateString) {
      result.isInTheFuture = true
      return result
    }
    //if by some freak of nature we get here
    return result
  }, [currentDate])

  const result = React.useMemo(() => {
    return unorderedTaskResponses.reduce<{
      incompleteOverdueTaskResponses: TaskResponse[]
      incompleteTaskResponses: TaskResponse[]
      completeTaskResponses: TaskResponse[]
      completeUnactionedTaskResponses: TaskResponse[]
    }>(
      (memo, taskResponse) => {
        if (
          taskResponse.completedTask
            ? !taskResponse.isAnimatingTaskCompletion
            : taskResponse.isAnimatingTaskUndo
        ) {
          if (taskResponse.completedTask?.taskActionVersionId) {
            memo.completeTaskResponses.push(taskResponse)
          } else {
            memo.completeUnactionedTaskResponses.push(taskResponse)
          }
        } else if (taskResponse.daysAvailable > 0) {
          memo.incompleteOverdueTaskResponses.push(taskResponse)
        } else {
          memo.incompleteTaskResponses.push(taskResponse)
        }
        return memo
      },
      {
        incompleteOverdueTaskResponses: [],
        incompleteTaskResponses: [],
        completeTaskResponses: [],
        completeUnactionedTaskResponses: [],
      },
    )
  }, [unorderedTaskResponses])

  const incompleteTaskResponses = React.useMemo(() => {
    return [
      ...result.incompleteOverdueTaskResponses,
      ...result.incompleteTaskResponses,
    ]
  }, [result.incompleteOverdueTaskResponses, result.incompleteTaskResponses])

  const taskResponses = React.useMemo(() => {
    return [
      ...result.completeUnactionedTaskResponses,
      ...incompleteTaskResponses,
      ...result.completeTaskResponses,
    ]
  }, [
    incompleteTaskResponses,
    result.completeTaskResponses,
    result.completeUnactionedTaskResponses,
  ])

  return (
    <>
      {isToday && (
        <SwipeableList>
          {taskResponses.map((taskResponse) => (
            <React.Fragment key={taskResponse.task.taskId}>
              <ScheduledTaskTodayListItem
                taskResponse={taskResponse}
                isSelected={selectedTaskId === taskResponse.task.taskId}
                onSelect={handleSelectTask}
                onAction={handleAction}
                onAnimatingTaskRemovalFinished={
                  handleAnimatingTaskRemovalFinished
                }
                setViewTaskActionSubmissionState={
                  setViewTaskActionSubmissionState
                }
              />
            </React.Fragment>
          ))}
        </SwipeableList>
      )}
      {isInThePast &&
        taskResponses.map(({ completedTask, task, daysAvailable, actions }) => {
          return !completedTask ? null : (
            <CompletedScheduledTaskListItemContent
              key={task.taskId}
              actions={actions}
              scheduledTask={task}
              completedTask={completedTask}
              daysAvailable={daysAvailable}
              isSelected={selectedTaskId === task.taskId}
              onSelect={handleSelectTask}
              setViewTaskActionSubmissionState={
                setViewTaskActionSubmissionState
              }
            />
          )
        })}
      {/* if the date is in the future, only show tasks with daysAvailable === 0 */}
      {isInTheFuture &&
        taskResponses.reduce<React.JSX.Element[]>(
          (components, { task, daysAvailable, actions }) => {
            if (daysAvailable > 0) {
              return components
            }
            components.push(
              <ScheduledTaskListItemContent
                key={task.taskId}
                isSelected={selectedTaskId === task.taskId}
                isRunningAction={false}
                actions={actions}
                scheduledTask={task}
                onAction={emptyFn}
                daysAvailable={daysAvailable}
                onSelect={handleSelectTask}
                isDisabled
                borderColor="primary"
              />,
            )
            return components
          },
          [],
        )}

      {isToday &&
        !incompleteTaskResponses.length &&
        !!taskResponses.length &&
        !isShowingCompletedTasks && (
          <div className="cypress-no-incomplete-tasks ob-scheduled-tasks__no-incomplete-tasks has-text-centered content">
            <p>
              <MaterialIcon className="has-text-success icon-x-large">
                check_circle
              </MaterialIcon>
            </p>
            <p>All of your tasks have been completed.</p>
            <p>
              Please turn on the <b>Show completed tasks</b> filter to display
              completed tasks.
            </p>
          </div>
        )}

      {(isInTheFuture || isToday) && !taskResponses.length && (
        <div className="cypress-no-scheduled-tasks ob-scheduled-tasks__no-scheduled-tasks has-text-centered content">
          <p>
            <MaterialIcon className="has-text-primary icon-x-large">
              event_available
            </MaterialIcon>
          </p>
          <p>There are no tasks scheduled on this day.</p>
        </div>
      )}

      {isInThePast && !taskResponses.length && (
        <div className="cypress-no-complete-tasks ob-scheduled-tasks__no-complete-tasks has-text-centered content">
          <p>
            <MaterialIcon className="has-text-primary icon-x-large">
              event_available
            </MaterialIcon>
          </p>
          <p>There were no tasks to complete on this day.</p>
        </div>
      )}

      <DraftModal
        onCancel={unfilterDrafts}
        draftsForForm={filteredDrafts}
        isSyncing={isSyncingAndReloadingDrafts}
        forms={forms}
      />
    </>
  )
}

export default React.memo(ScheduledTasksList)
