import * as React from 'react'
import { Snackbar, Button, LinearProgress, useMediaQuery } from '@mui/material'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import {
  OneBlinkAppsError,
  authService,
  draftService,
  localisationService,
  submissionService,
} from '@oneblink/apps'
import {
  OneBlinkAutoSaveForm,
  OneBlinkForm,
  useNullableState,
  useBooleanState,
  useIsMounted,
} from '@oneblink/apps-react'
import { formElementsService } from '@oneblink/sdk-core'
import { FormTypes, ScheduledTasksTypes } from '@oneblink/types'
import {
  Modal,
  ErrorModal,
  CopyToClipboardButton,
  MaterialIcon,
} from 'components'
import FormPostSubmissionReceipt from './FormPostSubmissionReceipt'
import {
  MenuItem,
  isPendingQueueEnabled,
  scheduledTaskGroupsMenuItem,
  scheduledTasksMenuItem,
} from 'services/menu-items-service'
import formsHostnameConfiguration from 'formsHostnameConfiguration'

const accountAttachmentRetentionInDays =
  formsHostnameConfiguration?.accountAttachmentRetentionInDays
const formsAttachmentRetention =
  formsHostnameConfiguration?.formsAttachmentRetention || []

type Props = {
  formsAppId: number | null
  jobId: string | null
  externalId: string | null
  task: ScheduledTasksTypes.Task | undefined
  taskAction: ScheduledTasksTypes.TaskAction | undefined
  taskGroup: ScheduledTasksTypes.TaskGroup | undefined
  taskGroupInstance: ScheduledTasksTypes.TaskGroupInstance | undefined
  preFillFormDataId: string | null
  isUsingFormsKey: boolean
  form: FormTypes.Form
  initialSubmission: Record<string, unknown> | undefined
  draftSubmission: submissionService.DraftSubmission | null
  isDraftsEnabled: boolean
  previousFormSubmissionApprovalId: string | null
  resumeAtElement?: FormTypes.FormElement
  preFillDataHashId: string | null
  editedFromCpHcmsContentMenuItem: MenuItem | undefined
  pendingTimestamp: string | undefined
}

function FormContainer({
  formsAppId,
  jobId,
  task,
  taskAction,
  taskGroup,
  taskGroupInstance,
  externalId,
  preFillFormDataId,
  isUsingFormsKey,
  form,
  initialSubmission,
  draftSubmission,
  isDraftsEnabled,
  previousFormSubmissionApprovalId,
  resumeAtElement,
  preFillDataHashId,
  editedFromCpHcmsContentMenuItem,
  pendingTimestamp,
}: Props) {
  const [cancelActionErrorMessage, setCancelActionErrorMessage] =
    React.useState<string | null>(null)
  const isMounted = useIsMounted()
  const history = useHistory()
  const [
    postSubmissionActionErrorMessage,
    setPostSubmissionActionErrorMessage,
  ] = React.useState<string | null>(null)
  const [tempDraftFormSubmissionResult, confirmSaveDraft, cancelSaveDraft] =
    useNullableState<submissionService.NewDraftSubmission>(null)
  const [draftTitle, setDraftTitle] = React.useState<string | undefined>(
    draftSubmission?.title,
  )

  const attachmentRetentionInDays = React.useMemo(() => {
    const formRetentionInDays = formsAttachmentRetention.find(
      ({ formId }) => formId === form.id,
    )
    if (formRetentionInDays) {
      return formRetentionInDays.days
    }
    return accountAttachmentRetentionInDays
  }, [form.id])

  let autoSaveKey = jobId || 'NO_JOB_ID'

  autoSaveKey = `${autoSaveKey}_${
    preFillFormDataId ? preFillFormDataId : 'NO_PRE_FILL_FORM_DATA_ID'
  }`

  autoSaveKey = `${autoSaveKey}_${
    draftSubmission?.formSubmissionDraftId || 'NO_DRAFT_DATA_ID'
  }`
  autoSaveKey = `${autoSaveKey}_${
    previousFormSubmissionApprovalId
      ? previousFormSubmissionApprovalId
      : 'NO_PREVIOUS_FORM_SUBMISSION_APPROVAL_ID'
  }`
  autoSaveKey = `${autoSaveKey}_${
    preFillDataHashId ? preFillDataHashId : 'NO_PRE_FILL_DATA_HASH_ID'
  }`

  const [
    { isSavingDraft, savingDraftProgress, saveDraftError },
    setSaveDraftState,
  ] = React.useState<{
    isSavingDraft: boolean
    savingDraftProgress?: number
    saveDraftError: Error | null
  }>({
    isSavingDraft: false,
    saveDraftError: null,
  })
  const clearSaveDraftError = React.useCallback(() => {
    setSaveDraftState({
      isSavingDraft: false,
      saveDraftError: null,
    })
  }, [])

  const [{ isSubmitting, submitError, formSubmissionResult }, setSubmitState] =
    React.useState<{
      formSubmissionResult: submissionService.FormSubmissionResult | null
      isSubmitting?: {
        progress: number | undefined
      }
      submitError: Error | null
    }>({
      formSubmissionResult: null,
      submitError: null,
    })
  const clearSubmitError = React.useCallback(() => {
    setSubmitState({
      formSubmissionResult: null,
      submitError: null,
    })
  }, [])

  const [formIsDisabled, disableForm, enableForm] = useBooleanState(false)

  const goBackToHCMSContent = React.useCallback(
    (menuItem: MenuItem) => history.replace(menuItem.href),
    [history],
  )

  const handlePostSubmissionAction = React.useCallback(
    async (formSubmissionResult: submissionService.FormSubmissionResult) => {
      try {
        if (editedFromCpHcmsContentMenuItem) {
          goBackToHCMSContent(editedFromCpHcmsContentMenuItem)
        } else {
          await submissionService.executePostSubmissionAction(
            formSubmissionResult,
            {
              onRedirectToRelativeUrl:
                !!formSubmissionResult.scheduling ||
                !!formSubmissionResult.payment
                  ? history.replace
                  : history.push,
              onRedirectToAbsoluteUrl: (url) =>
                !!formSubmissionResult.scheduling ||
                !!formSubmissionResult.payment
                  ? window.location.replace(url)
                  : window.location.assign(url),
            },
          )
        }
      } catch (error) {
        console.warn('Could not execute post submission action', error)
        if (isMounted.current) {
          setPostSubmissionActionErrorMessage((error as Error).message)
        }
      }
    },
    [
      editedFromCpHcmsContentMenuItem,
      goBackToHCMSContent,
      history.replace,
      history.push,
      isMounted,
    ],
  )

  const taskCompletion = React.useMemo<
    submissionService.FormSubmissionResult['taskCompletion']
  >(() => {
    if (task && taskAction) {
      if (taskGroupInstance && scheduledTaskGroupsMenuItem)
        return {
          task,
          taskAction,
          taskGroup,
          taskGroupInstance,
          redirectUrl: `${window.location.origin}${scheduledTaskGroupsMenuItem.defaultHref}/${taskGroupInstance.taskGroupInstanceId}`,
        }
      if (scheduledTasksMenuItem)
        return {
          task,
          taskAction,
          taskGroup,
          taskGroupInstance,
          redirectUrl: `${window.location.origin}${scheduledTasksMenuItem.href}`,
        }
    }
  }, [task, taskAction, taskGroup, taskGroupInstance])

  const handleCancel = React.useCallback(async () => {
    if (pendingTimestamp) {
      await submissionService.cancelEditingPendingQueueSubmission(
        pendingTimestamp,
      )
    }
    // Can't go in apps because it's not generic
    if (editedFromCpHcmsContentMenuItem) {
      return goBackToHCMSContent(editedFromCpHcmsContentMenuItem)
    }
    try {
      await submissionService.executeCancelAction(
        {
          definition: form,
          externalId,
          taskCompletion,
        },
        {
          onRedirectToRelativeUrl: history.push,
          onRedirectToAbsoluteUrl: (url) => window.location.assign(url),
        },
      )
    } catch (err) {
      setCancelActionErrorMessage((err as Error).message)
    }
  }, [
    pendingTimestamp,
    editedFromCpHcmsContentMenuItem,
    goBackToHCMSContent,
    form,
    externalId,
    taskCompletion,
    history.push,
  ])

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setCancelActionErrorMessage(null)
  }

  const handleSubmit = React.useCallback(
    async (newFormSubmission: submissionService.NewFormSubmission) => {
      disableForm()

      const formSubmission: submissionService.FormSubmission = {
        ...newFormSubmission,
        formsAppId: formsAppId || undefined,
        jobId: jobId || undefined,
        externalId: externalId || undefined,
        formSubmissionDraftId: draftSubmission?.formSubmissionDraftId,
        preFillFormDataId: preFillFormDataId,
        previousFormSubmissionApprovalId:
          previousFormSubmissionApprovalId || undefined,
        taskCompletion,
      }

      const formSubmissionResult: submissionService.FormSubmissionResult = {
        ...formSubmission,
        isInPendingQueue: false,
        isOffline: false,
        payment: null,
        scheduling: null,
        submissionId: null,
        submissionTimestamp: null,
        isUploadingAttachments: false,
      }

      if (
        formElementsService.determineIsInfoPage(newFormSubmission.definition)
      ) {
        return handlePostSubmissionAction(formSubmissionResult)
      }

      setSubmitState({
        formSubmissionResult: null,
        submitError: null,
        isSubmitting: { progress: undefined },
      })

      try {
        const newFormSubmissionResult = await submissionService.submit({
          formSubmission,
          paymentReceiptUrl: `${window.location.origin}/payment-receipt`,
          paymentFormUrl: `${window.location.origin}/payment-form`,
          schedulingUrlConfiguration: {
            schedulingBookingUrl: `${window.location.origin}/calendar-bookings/schedule`,
            schedulingRescheduleUrl: `${window.location.origin}/calendar-bookings/reschedule`,
            schedulingCancelUrl: `${window.location.origin}/calendar-bookings/cancel`,
          },
          autoSaveKey,
          onProgress: ({ progress }) => {
            console.log('Submission upload progress', progress)
            setSubmitState((currentState) => ({
              ...currentState,
              isSubmitting: { progress },
            }))
          },
          isPendingQueueEnabled,
          shouldRunExternalIdGeneration: true,
          shouldRunServerValidation: true,
          pendingTimestamp,
        })
        if (
          newFormSubmissionResult.isOffline &&
          !newFormSubmissionResult.isInPendingQueue
        ) {
          throw new OneBlinkAppsError(
            'You cannot submit this form while offline, please try again when connectivity is restored.',
            {
              isOffline: true,
            },
          )
        }

        // If redirecting after submission, execute post submission
        // action immediately without stopping the submitting animation
        if (
          newFormSubmissionResult.submissionId &&
          ((newFormSubmissionResult.definition.postSubmissionAction === 'URL' &&
            !Object.keys(
              newFormSubmissionResult.definition.postSubmissionReceipt ?? {},
            ).length) ||
            newFormSubmissionResult.payment ||
            newFormSubmissionResult.scheduling)
        ) {
          return handlePostSubmissionAction(newFormSubmissionResult)
        }

        if (isMounted.current) {
          setSubmitState({
            formSubmissionResult: newFormSubmissionResult,
            submitError: null,
          })
        }
      } catch (error) {
        if (isMounted.current) {
          enableForm()
          setSubmitState({
            formSubmissionResult: null,
            submitError: error as Error,
          })
        }
      }
    },
    [
      pendingTimestamp,
      disableForm,
      formsAppId,
      jobId,
      externalId,
      draftSubmission?.formSubmissionDraftId,
      preFillFormDataId,
      previousFormSubmissionApprovalId,
      handlePostSubmissionAction,
      taskCompletion,
      autoSaveKey,
      isMounted,
      enableForm,
    ],
  )

  const handleConfirmedSaveDraft = React.useCallback(
    async (newDraftSubmission: submissionService.NewDraftSubmission) => {
      if (!formsAppId) {
        return
      }

      disableForm()
      const draftSubmissionInput: submissionService.DraftSubmissionInput = {
        ...newDraftSubmission,
        title: draftTitle || form.name,
        formsAppId,
        jobId: jobId || undefined,
        externalId: externalId || undefined,
        previousFormSubmissionApprovalId:
          previousFormSubmissionApprovalId || undefined,
        taskCompletion,
      }
      setSaveDraftState({
        saveDraftError: null,
        isSavingDraft: true,
      })

      try {
        const onProgress = ({ progress }: { progress: number }) => {
          console.log('Draft upload progress', progress)
          setSaveDraftState((currentState) => ({
            ...currentState,
            savingDraftProgress: progress,
          }))
        }
        await draftService.upsertDraft({
          formSubmissionDraftId: draftSubmission?.formSubmissionDraftId,
          draftSubmissionInput,
          autoSaveKey,
          onProgress,
          pendingTimestamp,
        })

        if (isMounted.current) {
          setSaveDraftState((currentState) => ({
            ...currentState,
            saveDraftError: null,
            isSavingDraft: false,
          }))
          // If a draft is saved for key, we will execute the post submission action
          // otherwise we run the equivalent of cancelling the form submission
          if (isUsingFormsKey) {
            await handlePostSubmissionAction({
              ...draftSubmissionInput,
              preFillFormDataId: preFillFormDataId,
              recaptchas: [],
              isInPendingQueue: false,
              isOffline: false,
              payment: null,
              scheduling: null,
              submissionId: null,
              submissionTimestamp: null,
              isUploadingAttachments: false,
            })
          } else {
            if (taskCompletion && scheduledTasksMenuItem) {
              history.replace(scheduledTasksMenuItem.href)
              return
            }
            if (
              taskCompletion?.taskGroupInstance?.taskGroupInstanceId &&
              scheduledTaskGroupsMenuItem
            ) {
              history.replace(
                `${scheduledTaskGroupsMenuItem.defaultHref}/${taskCompletion?.taskGroupInstance?.taskGroupInstanceId}`,
              )
              return
            }
            await submissionService.goBackOrCloseWindow()
          }
        }
      } catch (error) {
        if (isMounted.current) {
          enableForm()
          setSaveDraftState({
            isSavingDraft: false,
            saveDraftError: error as Error,
          })
        }
      }
    },
    [
      disableForm,
      enableForm,
      draftTitle,
      form.name,
      formsAppId,
      jobId,
      externalId,
      previousFormSubmissionApprovalId,
      taskCompletion,
      draftSubmission?.formSubmissionDraftId,
      isMounted,
      autoSaveKey,
      isUsingFormsKey,
      handlePostSubmissionAction,
      preFillFormDataId,
      history,
      pendingTimestamp,
    ],
  )

  const handleSaveDraft = React.useMemo(() => {
    if (!isDraftsEnabled) {
      return
    }
    return async (newDraftSubmission: submissionService.NewDraftSubmission) => {
      // set default draft title if it does not already have a title
      if (!draftTitle) {
        if (form.submissionTitle) {
          const submissionTitle =
            localisationService.replaceInjectablesWithSubmissionValues(
              form.submissionTitle,
              {
                form: newDraftSubmission.definition,
                submission: newDraftSubmission.submission,
                submissionId: '',
                submissionTimestamp: '',
                externalId: externalId || undefined,
                previousApprovalId:
                  previousFormSubmissionApprovalId || undefined,
                userProfile: authService.getUserProfile() || undefined,
                task: taskCompletion?.task,
                taskGroup: taskCompletion?.taskGroup,
                taskGroupInstance: taskCompletion?.taskGroupInstance,
              },
            ).text
          setDraftTitle(submissionTitle)
        } else {
          setDraftTitle(form.name)
        }
      }
      if (isUsingFormsKey) {
        await handleConfirmedSaveDraft(newDraftSubmission)
      } else {
        confirmSaveDraft(newDraftSubmission)
      }
    }
  }, [
    confirmSaveDraft,
    draftTitle,
    externalId,
    form.name,
    form.submissionTitle,
    handleConfirmedSaveDraft,
    isDraftsEnabled,
    isUsingFormsKey,
    previousFormSubmissionApprovalId,
    taskCompletion?.task,
    taskCompletion?.taskGroup,
    taskCompletion?.taskGroupInstance,
  ])
  const isMobile = useMediaQuery('(max-width:769px)')
  const navigableValidationErrorsNotificationSettings = React.useMemo(() => {
    const hasTilesNavigation = formsHostnameConfiguration?.type === 'TILES'
    if (isMobile) {
      const topPageNavigationHeight = 48
      if (hasTilesNavigation) {
        const tilesNavigationHeightAtMobile = 48
        return {
          navigationTopOffset:
            tilesNavigationHeightAtMobile + topPageNavigationHeight,
        }
      }
      return {
        navigationTopOffset: topPageNavigationHeight,
      }
    }
    if (hasTilesNavigation) {
      const tilesNavigationHeight = 64
      return {
        navigationTopOffset: tilesNavigationHeight,
      }
    }
  }, [isMobile])
  return (
    <>
      {form.disableAutosave ? (
        <OneBlinkForm
          captchaType={formsHostnameConfiguration?.recaptchaKeyType}
          captchaSiteKey={formsHostnameConfiguration?.recaptchaPublicKey}
          abnLookupAuthenticationGuid={
            formsHostnameConfiguration?.abnLookupAuthenticationGuid
          }
          googleMapsApiKey={formsHostnameConfiguration?.googleMapsApiKey}
          initialSubmission={initialSubmission}
          form={form}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          onSaveDraft={handleSaveDraft}
          disabled={formIsDisabled}
          buttons={formsHostnameConfiguration?.styles?.buttons}
          primaryColour={formsHostnameConfiguration?.styles?.highlightColour}
          attachmentRetentionInDays={attachmentRetentionInDays}
          isPendingQueueEnabled={isPendingQueueEnabled}
          resumeAtElement={resumeAtElement}
          task={task}
          taskGroup={taskGroup}
          taskGroupInstance={taskGroupInstance}
          navigableValidationErrorsNotificationSettings={
            navigableValidationErrorsNotificationSettings
          }
        />
      ) : (
        <OneBlinkAutoSaveForm
          captchaType={formsHostnameConfiguration?.recaptchaKeyType}
          captchaSiteKey={formsHostnameConfiguration?.recaptchaPublicKey}
          abnLookupAuthenticationGuid={
            formsHostnameConfiguration?.abnLookupAuthenticationGuid
          }
          googleMapsApiKey={formsHostnameConfiguration?.googleMapsApiKey}
          initialSubmission={initialSubmission}
          form={form}
          autoSaveKey={autoSaveKey}
          removeAutoSaveDataBeforeSaveDraft={false}
          removeAutoSaveDataBeforeSubmit={false}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          onSaveDraft={handleSaveDraft}
          disabled={formIsDisabled}
          buttons={formsHostnameConfiguration?.styles?.buttons}
          primaryColour={formsHostnameConfiguration?.styles?.highlightColour}
          attachmentRetentionInDays={attachmentRetentionInDays}
          isPendingQueueEnabled={isPendingQueueEnabled}
          resumeAtElement={resumeAtElement}
          task={task}
          taskGroup={taskGroup}
          taskGroupInstance={taskGroupInstance}
          navigableValidationErrorsNotificationSettings={
            navigableValidationErrorsNotificationSettings
          }
        />
      )}
      <Modal
        title="Submitting Form..."
        isOpen={isSubmitting !== undefined}
        actions={null}
        cardClassName="cypress-submitting has-text-centered"
      >
        <LinearProgress
          className="has-text-centered ob-progress__submission-bar"
          variant={
            isSubmitting?.progress !== undefined
              ? 'determinate'
              : 'indeterminate'
          }
          value={isSubmitting?.progress}
        />
      </Modal>
      <ErrorModal error={submitError} onClose={clearSubmitError} />
      <Modal
        title={`${draftSubmission ? 'Updating' : 'Saving'} Draft...`}
        isOpen={isSavingDraft}
        actions={null}
        cardClassName="cypress-saving-draft has-text-centered"
      >
        <LinearProgress
          className="has-text-centered ob-progress__submission-bar"
          variant={
            savingDraftProgress !== undefined ? 'determinate' : 'indeterminate'
          }
          value={savingDraftProgress}
        />
      </Modal>
      {!!tempDraftFormSubmissionResult && (
        <Modal
          isOpen={!isSavingDraft}
          title="Draft Title"
          cardClassName="cypress-draft-details"
          titleClassName="cypress-draft-title"
          bodyClassName="cypress-draft-body"
          actions={
            <>
              <button
                type="button"
                className="button ob-button is-light cypress-draft-details-cancel"
                onClick={cancelSaveDraft}
              >
                Cancel
              </button>
              <button
                type="button"
                className="button ob-button is-primary cypress-draft-details-save"
                onClick={() =>
                  handleConfirmedSaveDraft(tempDraftFormSubmissionResult)
                }
                autoFocus
              >
                Save
              </button>
            </>
          }
        >
          <input
            className="input ob-draft-title cypress-draft-title-input"
            value={draftTitle}
            onChange={(e) => setDraftTitle(e.target.value)}
            aria-label="Draft Title"
          />
          {!!formsHostnameConfiguration?.draftsAreShared && (
            <div className="notification ob-notification is-info is-light ob-notification__shared-draft has-margin-top-7 cypress-draft-details-shared-draft-notification">
              Your draft will be shared with all other app users.
            </div>
          )}
        </Modal>
      )}
      <ErrorModal error={saveDraftError} onClose={clearSaveDraftError} />
      {formSubmissionResult && (
        <>
          <Modal
            isOpen={!!formSubmissionResult.isInPendingQueue}
            title={
              formSubmissionResult.isOffline
                ? "It looks like you're Offline"
                : 'Item added to pending queue'
            }
            cardClassName="cypress-submission-offline has-text-centered"
            titleClassName="cypress-offline-title"
            bodyClassName="cypress-offline-body"
            actions={
              <button
                className="button ob-button is-primary"
                onClick={() => handlePostSubmissionAction(formSubmissionResult)}
                autoFocus
              >
                Okay
              </button>
            }
          >
            <p>
              {formSubmissionResult.isOffline
                ? 'Your submission has been saved to the pending queue. It will be uploaded once connectivity is restored, whilst the app is open.'
                : 'Your Submission has been saved to the pending queue. It will be uploaded in the background, whilst the app is open.'}
            </p>
            <MaterialIcon className="has-text-warning icon-x-large">
              {formSubmissionResult.isOffline ? 'wifi_off' : 'pending_actions'}
            </MaterialIcon>
          </Modal>

          {!!formSubmissionResult.submissionId && (
            <Modal
              isOpen
              title={form.postSubmissionReceipt?.html ? undefined : 'Success'}
              titleClassName="ob-submission-success__title cypress-success-title"
              cardClassName={clsx(
                'ob-submission-success__modal cypress-submission-success',
                {
                  'has-text-centered': !form.postSubmissionReceipt?.html,
                },
              )}
              actions={
                <div className="submission-success-modal-actions-wrapper">
                  {formSubmissionResult.downloadSubmissionPdfUrl ? (
                    <a
                      className="button ob-button ob-button__download-pdf is-light cypress-receipt-download-pdf-button"
                      href={formSubmissionResult.downloadSubmissionPdfUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download PDF
                    </a>
                  ) : (
                    <span />
                  )}
                  <button
                    className="button ob-button ob-submission-success__ok-button is-primary cypress-receipt-ok-button"
                    onClick={() =>
                      handlePostSubmissionAction(formSubmissionResult)
                    }
                    autoFocus
                  >
                    Okay
                  </button>
                </div>
              }
            >
              {form.postSubmissionReceipt?.html ? (
                <FormPostSubmissionReceipt
                  postSubmissionReceiptHtml={form.postSubmissionReceipt.html}
                  formSubmissionResult={formSubmissionResult}
                />
              ) : (
                <>
                  <MaterialIcon className="has-text-success icon-x-large ob-submission-success__icon">
                    check_circle
                  </MaterialIcon>
                  <div className="ob-submission-success-id">
                    {formSubmissionResult.externalId ? (
                      <div className="ob-submission-success__external-id cypress-submission-success-external-id">
                        <p className="ob-submission-success__external-id-label">
                          ID:{' '}
                        </p>
                        <p className="ob-submission-success__external-id-value">
                          {formSubmissionResult.externalId}
                        </p>
                      </div>
                    ) : (
                      <p className="cypress-submission-success-id">
                        Submission ID: {formSubmissionResult.submissionId}
                      </p>
                    )}
                    <CopyToClipboardButton
                      text={
                        formSubmissionResult.externalId ??
                        formSubmissionResult.submissionId
                      }
                      className="button cypress-copy-to-clipboard-submission-id-button"
                    />
                  </div>
                </>
              )}
            </Modal>
          )}
        </>
      )}
      <Modal
        isOpen={!!postSubmissionActionErrorMessage}
        cardClassName="cypress-error-modal"
        titleClassName="cypress-error-title"
        bodyClassName="cypress-error-message"
        actions={null}
      >
        <p>{postSubmissionActionErrorMessage}</p>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!cancelActionErrorMessage}
        autoHideDuration={50000}
        message={cancelActionErrorMessage}
        onClose={handleClose}
        action={
          <Button onClick={handleClose} className="ob-snack-button">
            Ok
          </Button>
        }
      />
    </>
  )
}

export default React.memo<Props>(FormContainer)
