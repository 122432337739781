import * as React from 'react'

import {
  ErrorModal,
  LoginSignupButtons,
  OnLoading,
  MaterialIcon,
  DraftModal,
} from 'components'
import { useAuth } from '@oneblink/apps-react'
import useFetchForms from '../hooks/useFetchForms'
import formsHostnameConfiguration from 'formsHostnameConfiguration'

const homeScreenName = formsHostnameConfiguration?.pwaSettings?.homeScreenName
const foregroundColour = formsHostnameConfiguration?.styles?.foregroundColour
const logoUrl = formsHostnameConfiguration?.styles?.logoUrl
const appType = formsHostnameConfiguration?.type
const isAppUserSignUpEnabled =
  formsHostnameConfiguration?.isAppUserSignUpEnabled

function Header() {
  if (logoUrl) {
    return (
      <img src={logoUrl} alt="Application Banner Logo" className="ob-logo" />
    )
  }

  return (
    <div>
      <h1 className="ob-header__heading">{homeScreenName || 'Forms'}</h1>
      <hr className="divider" />
    </div>
  )
}

function FormList() {
  const { isLoggedIn } = useAuth()
  const [query, setQuery] = React.useState('')
  const {
    draftsForForm,
    loadError,
    isLoading,
    forms,
    isSyncingAndReloadingDrafts,
    actions: { selectForm, deselectForm, clearLoadError },
  } = useFetchForms()

  return (
    <div className="form-list">
      <ErrorModal error={loadError} onClose={clearLoadError} />

      <DraftModal
        onCancel={deselectForm}
        draftsForForm={draftsForForm}
        isSyncing={isSyncingAndReloadingDrafts}
        forms={forms}
      />

      <div className="ob-content">
        {appType !== 'TILES' && (
          <div
            className="div__header ob-header"
            style={
              foregroundColour
                ? { backgroundColor: foregroundColour }
                : undefined
            }
          >
            <Header />
            <input
              placeholder="Search for a Form..."
              aria-label="Search for a Form"
              value={query}
              onChange={(event) => setQuery(event.target.value)}
              className="searchbar input ob-searchbar"
              role="search"
            />
          </div>
        )}
        <div className="div__wrapper ob-formslist">
          {isLoading ? (
            <div className="cypress-loading has-text-centered">
              <OnLoading className="has-text-centered" />
              <span>Retrieving Forms</span>
            </div>
          ) : forms.length ? (
            <ul className="cypress-forms-list ul__formslist">
              {forms
                .filter(
                  (form) =>
                    !query ||
                    form.name.toLowerCase().includes(query.toLowerCase()),
                )
                .map((form) => (
                  <li
                    key={form.id}
                    className={`li__formslist ${
                      form.customCssClasses?.join(' ') ?? ''
                    }`}
                  >
                    <button
                      type="button"
                      onClick={() => selectForm(form.id)}
                      id={`form-${form.id}`}
                      className="button ob-button btn__formlink cypress-forms-list-form"
                    >
                      {form.name}
                    </button>
                  </li>
                ))}
            </ul>
          ) : isLoggedIn ? (
            // SHOW NO FORMS MESSAGE IF THERE ARE NO FORMS AND USER IS LOGGED IN
            <div className="cypress-forms-empty ob-noforms div__noformscontainer">
              <div className="panel__noforms">
                There are currently no forms or info pages in your app
              </div>
            </div>
          ) : (
            // SHOW LOGIN MESSAGE IF THERE ARE NO FORMS AND USER NOT LOGGED IN
            <section>
              <div className="has-text-centered has-margin-bottom-8">
                <MaterialIcon className="has-text-grey icon-x-large">
                  lock
                </MaterialIcon>
              </div>

              <div className="has-text-centered ob-login__container">
                <p className="cypress-login-required-message ob-text__login-required has-margin-bottom-4">
                  {`There are no forms available, try logging in${
                    isAppUserSignUpEnabled ? ' or signing up' : ''
                  }.`}
                </p>
                <LoginSignupButtons />
              </div>
            </section>
          )}
        </div>
      </div>
    </div>
  )
}

export default React.memo(FormList)
