import * as React from 'react'
import hash from 'object-hash'
import {
  OneBlinkAppsError,
  approvalsService,
  draftService,
  prefillService,
  scheduledTasksService,
  submissionService,
} from '@oneblink/apps'
import { useAuth, useLoadDataEffect } from '@oneblink/apps-react'
import { FormTypes, ScheduledTasksTypes } from '@oneblink/types'
import formsApiService from 'services/forms-api-service'
import runPersonalisation from 'services/runPersonalisation'
import { MenuItem, scheduledTasksLabel } from 'services/menu-items-service'
import useIsAuthorised from 'hooks/useIsAuthorised'
import useForm from 'hooks/useForm'
import { getDateString } from 'hooks/useScheduledTasks'
import { ErrorModal, OnLoading } from 'components'
import FormUnpublishedModal from 'components/modals/formUnpublishedModal'
import RefreshSnackbar from 'components/RefreshSnackbar'
import FormContainer from 'renderer/FormContainer'
import useServiceWorkerMessageEffect from 'service-worker/useServiceWorkerMessageEffect'
import { useGlobalNewContentSnackbarState } from 'service-worker/NewContentSnackbar'
import formsHostnameConfiguration from 'formsHostnameConfiguration'
import { CP_HCMS_CONTENT_PREFILL_PREFIX } from '../cp-hcms-content/CPHCMSContentItemsList'

const isDraftsEnabled = !!formsHostnameConfiguration?.isDraftsEnabled

type Props = {
  formsAppId: number | null
  formId: number | null
  formSlug: string | null
  draftId: string | null
  preFillFormDataId: string | null
  preFillData: string | null
  cpHCMSPreFillDataId: string | null
  jobId: string | null
  externalId: string | null
  previousFormSubmissionApprovalId: string | null
  taskId: string | null
  taskActionId: string | null
  taskGroupInstanceId: string | null
  editedFromCpHcmsContentMenuItem: MenuItem | undefined
  pendingTimestamp: string | undefined
}

function FormLoader({
  formsAppId,
  formSlug,
  formId,
  draftId,
  preFillFormDataId,
  preFillData,
  cpHCMSPreFillDataId,
  jobId,
  externalId: externalIdUrlSearchParam,
  previousFormSubmissionApprovalId,
  taskId,
  taskActionId,
  taskGroupInstanceId,
  editedFromCpHcmsContentMenuItem,
  pendingTimestamp,
}: Props) {
  const isAuthorised = useIsAuthorised()
  const { isUsingFormsKey } = useAuth()
  const { setForm } = useForm()
  const { swNeedRefresh } = useGlobalNewContentSnackbarState()

  const [
    {
      isLoading,
      loadError,
      form,
      draftSubmission,
      draftDataUnavailableError,
      preFillDataUnavailableError,
      formNotPublishedError,
      taskNotAvailableError,
      previousFormSubmissionApprovalStatusError,
      externalId,
      initialSubmission,
      resumeAtElement,
      preFillDataHashId,
      task,
      taskAction,
      taskGroup,
      taskGroupInstance,
      needsReload,
    },
    setLoadState,
  ] = React.useState<{
    isLoading: boolean
    loadError: Error | null
    form: FormTypes.Form | null
    draftSubmission: submissionService.DraftSubmission | null
    draftDataUnavailableError: null
    preFillDataUnavailableError: Error | null
    formNotPublishedError: Error | null
    taskNotAvailableError: Error | null
    previousFormSubmissionApprovalStatusError: Error | null
    externalId: string | null
    initialSubmission?: Record<string, unknown>
    resumeAtElement?: FormTypes.FormElement
    preFillDataHashId: string | null
    task: ScheduledTasksTypes.Task | undefined
    taskAction: ScheduledTasksTypes.TaskAction | undefined
    taskGroup: ScheduledTasksTypes.TaskGroup | undefined
    taskGroupInstance: ScheduledTasksTypes.TaskGroupInstance | undefined
    needsReload: boolean
  }>({
    isLoading: true,
    loadError: null,
    form: null,
    draftSubmission: null,
    draftDataUnavailableError: null,
    preFillDataUnavailableError: null,
    formNotPublishedError: null,
    taskNotAvailableError: null,
    previousFormSubmissionApprovalStatusError: null,
    externalId: externalIdUrlSearchParam,
    preFillDataHashId: null,
    task: undefined,
    taskAction: undefined,
    taskGroup: undefined,
    taskGroupInstance: undefined,
    needsReload: false,
  })

  // Load the form, draft data, and prefill data
  const loadForm = React.useCallback(
    async (abortSignal: AbortSignal) => {
      setLoadState((currentState) => ({
        ...currentState,
        isLoading: true,
        loadError: null,
        draftDataUnavailableError: null,
        preFillDataUnavailableError: null,
        needsReload: false,
      }))

      let newLoadError = null
      let newDraftSubmission = null
      let newInitialSubmission: Record<string, unknown> | null = null
      let newResumeAtElement: FormTypes.FormElement | undefined
      let newDraftDataUnavailableError = null
      let newPreFillDataUnavailableError = null
      let newFormNotPublishedError = null
      let newTaskNotAvailableError = null
      let newPreviousFormSubmissionApprovalStatusError = null
      let preFillDataHashId = null
      let newTask: ScheduledTasksTypes.Task | undefined = undefined
      let newTaskAction: ScheduledTasksTypes.TaskAction | undefined = undefined
      let newTaskGroup: ScheduledTasksTypes.TaskGroup | undefined = undefined
      let newTaskGroupInstance:
        | ScheduledTasksTypes.TaskGroupInstance
        | undefined = undefined

      const results = await Promise.all([
        formsApiService
          .getForm({
            formSlug,
            formId,
            toCompleteTask: !!taskId,
            abortSignal: abortSignal,
          })
          .then<{
            newForm: FormTypes.Form | null
            personalisationResult: Awaited<
              ReturnType<typeof runPersonalisation>
            > | null
          }>((newForm) => {
            // Check if form is published
            const startDate = newForm.publishStartDate
              ? new Date(newForm.publishStartDate)
              : null
            const endDate = newForm.publishEndDate
              ? new Date(newForm.publishEndDate)
              : null
            const now = new Date()
            // If now is before startDate or after endDate
            if ((startDate && now < startDate) || (endDate && now > endDate)) {
              newFormNotPublishedError = new Error(
                newForm.unpublishedUserMessage,
              )
              return { newForm: null, personalisationResult: null }
            }
            return runPersonalisation(
              newForm.personalisation,
              {
                externalIdUrlSearchParam,
                formsAppId,
                formId: newForm.id,
                draftId,
                preFillFormDataId,
                jobId,
                previousFormSubmissionApprovalId,
              },
              abortSignal,
            ).then((personalisationResult) => {
              if (Array.isArray(personalisationResult.elements)) {
                console.log('Setting elements based on personalisation result')
                newForm.elements = personalisationResult.elements
                newForm.isMultiPage =
                  personalisationResult.elements[0]?.type === 'page'
              }
              return {
                newForm,
                personalisationResult,
              }
            })
          })
          .catch((error) => {
            newLoadError = error
            return { newForm: null, personalisationResult: null }
          }),
        formsAppId
          ? draftService
              .getDraftAndData(formsAppId, draftId, abortSignal)
              .catch((error) => {
                newDraftDataUnavailableError = error
              })
          : Promise.resolve(null),
        formId
          ? prefillService
              .getPrefillFormData(formId, preFillFormDataId)
              .catch((error) => {
                newPreFillDataUnavailableError = error
                return null
              })
          : Promise.resolve(null),
        (async () => {
          try {
            if (taskId && formsAppId) {
              const date = getDateString(new Date())
              let taskResponses: scheduledTasksService.TaskResponse[] = []
              if (taskGroupInstanceId) {
                const result =
                  await scheduledTasksService.getTaskGroupInstanceTasks({
                    taskGroupInstanceId,
                    date,
                    formsAppId,
                    abortSignal: abortSignal,
                  })
                taskResponses = result.taskResponses
                newTaskGroup = result.taskGroup
                newTaskGroupInstance = result.taskGroupInstance
              } else {
                const result = await scheduledTasksService.getTasksForFormsApp({
                  formsAppId,
                  date,
                  abortSignal: abortSignal,
                })
                taskResponses = result.taskResponses
              }

              const taskResponse = taskResponses.find(({ task }) => {
                return task.taskId === taskId
              })
              newTask = taskResponse?.task
              if (!newTask) {
                throw new OneBlinkAppsError(
                  `The task you are attempting to complete has already been completed. Please go back to ${scheduledTasksLabel} to continue.`,
                  {
                    title: 'Task Already Complete',
                  },
                )
              }

              newTaskAction = taskResponse?.actions.find(
                (ta) =>
                  ta.taskActionId === taskActionId &&
                  newTask?.actionIds.includes(taskActionId),
              )
              if (!newTaskAction) {
                throw new OneBlinkAppsError(
                  `The task you are attempting to complete does not support this action. Please go back to ${scheduledTasksLabel} to continue.`,
                  {
                    title: 'Unavailable Action',
                  },
                )
              }
            }
          } catch (error) {
            newTaskNotAvailableError = error
          }
        })(),
        (async () => {
          try {
            if (previousFormSubmissionApprovalId) {
              const { status } =
                await approvalsService.getFormSubmissionApprovalStatus(
                  previousFormSubmissionApprovalId,
                  abortSignal,
                )
              if (status !== 'CLARIFICATION_REQUIRED') {
                throw new OneBlinkAppsError(
                  'The clarification you are viewing has already been actioned. Please contact your administrator to make any changes.',
                  {
                    title: 'Clarification Already Actioned',
                  },
                )
              }
            }
          } catch (error) {
            if (error instanceof OneBlinkAppsError) {
              // API will return conflict (409) or badData(422) if the clarification has already been actioned
              if (
                error.httpStatusCode === 409 ||
                error.httpStatusCode === 422
              ) {
                newPreviousFormSubmissionApprovalStatusError =
                  new OneBlinkAppsError(
                    'The clarification you are viewing has already been actioned. Please contact your administrator to make any changes.',
                    {
                      title: 'Clarification Already Actioned',
                    },
                  )
              }
            } else {
              newPreviousFormSubmissionApprovalStatusError = error
            }
          }
        })(),
      ])

      const { newForm, personalisationResult } = results[0]

      // Attempt to get initial model from draft data
      newDraftSubmission = results[1] || null
      if (newDraftSubmission) {
        newInitialSubmission = newDraftSubmission.submission
        newResumeAtElement = newDraftSubmission.lastElementUpdated
      } else {
        // If there is no draft data, use prefill data as initial model
        newInitialSubmission = personalisationResult?.submission || results[2]

        // If we have a cp prefill query param then try and fetch it
        if (cpHCMSPreFillDataId) {
          const cpHCMSPreFillText = sessionStorage.getItem(
            CP_HCMS_CONTENT_PREFILL_PREFIX + cpHCMSPreFillDataId,
          )
          if (cpHCMSPreFillText) {
            try {
              newInitialSubmission = {
                ...newInitialSubmission,
                ...JSON.parse(cpHCMSPreFillText),
              }
            } catch (e) {
              console.warn(
                `session storage for CP HCMS preFill with id ${cpHCMSPreFillDataId} misconfigured`,
              )
              //do nothing
            }
          }
        }

        // Combine prefill data from query string with stored prefill data
        if (preFillData) {
          try {
            const queryStringPreFillData = JSON.parse(preFillData)
            newInitialSubmission = Object.assign(
              {},
              newInitialSubmission,
              queryStringPreFillData,
            )
            preFillDataHashId = hash(queryStringPreFillData)
          } catch (error) {
            newPreFillDataUnavailableError = new OneBlinkAppsError(
              'The prefill data embedded in the current URL is malformed or does not match the current form.',
              {
                title: 'Invalid Prefill Data',
                originalError: error as Error,
              },
            )
          }
        }
      }

      if (!abortSignal.aborted) {
        setLoadState({
          isLoading: false,
          loadError: newLoadError,
          form: newForm,
          draftSubmission: newDraftSubmission,
          externalId: externalIdUrlSearchParam,
          initialSubmission: newInitialSubmission || undefined,
          resumeAtElement: newResumeAtElement,
          draftDataUnavailableError: newDraftDataUnavailableError,
          preFillDataUnavailableError: newPreFillDataUnavailableError,
          formNotPublishedError: newFormNotPublishedError,
          taskNotAvailableError: newTaskNotAvailableError,
          previousFormSubmissionApprovalStatusError:
            newPreviousFormSubmissionApprovalStatusError,
          preFillDataHashId,
          task: newTask,
          taskAction: newTaskAction,
          taskGroup: newTaskGroup,
          taskGroupInstance: newTaskGroupInstance,
          needsReload: false,
        })
        setForm(newForm)
      }
    },
    [
      draftId,
      externalIdUrlSearchParam,
      formId,
      formSlug,
      formsAppId,
      jobId,
      preFillData,
      cpHCMSPreFillDataId,
      preFillFormDataId,
      previousFormSubmissionApprovalId,
      setForm,
      taskActionId,
      taskGroupInstanceId,
      taskId,
    ],
  )
  const refreshForm = useLoadDataEffect(loadForm)

  useServiceWorkerMessageEffect(
    React.useCallback(
      (payload) => {
        if (
          payload.contentToRefresh === 'singleForm' &&
          ((payload.type === 'ID' && payload.formId === formId) ||
            (payload.type === 'SLUG' && payload.formSlug === formSlug))
        ) {
          setLoadState((current) => ({
            ...current,
            needsReload: true,
          }))
        }
      },
      [formId, formSlug],
    ),
  )

  const freshdeskAddTicketToNoteError = React.useMemo(() => {
    if (!form) {
      return
    }

    const events = []
    if (form.submissionEvents) {
      events.push(...form.submissionEvents)
    }
    if (form.draftEvents) {
      events.push(...form.draftEvents)
    }
    if (form.approvalEvents) {
      events.push(...form.approvalEvents)
    }

    if (!events.length) {
      return
    }

    if (
      !externalId &&
      events.some((event) => event.type === 'FRESHDESK_ADD_NOTE_TO_TICKET')
    ) {
      return new OneBlinkAppsError(
        'You have been directed to this page with insufficient details. Please contact your administrator to rectify the issue.',
        {
          title: 'Incomplete Workflow Configuration',
          originalError: new Error(
            '"externalId" is required to complete this form.',
          ),
        },
      )
    }
  }, [externalId, form])
  React.useEffect(() => {
    if (form) {
      document.title = form.name
    }
  }, [form])

  if (isLoading || isAuthorised === null) {
    return (
      <section
        className={`section forms-renderer cypress-form-scene ${
          form?.customCssClasses?.join(' ') ?? ''
        }`}
        id={formId ? `form-${formId}` : undefined}
      >
        <div className="container">
          <div className="cypress-loading has-text-centered">
            <OnLoading className="has-text-centered"></OnLoading>
            <span>Retrieving Form</span>
          </div>
        </div>
      </section>
    )
  }

  if (taskNotAvailableError) {
    return (
      <ErrorModal
        error={taskNotAvailableError}
        onClose={submissionService.goBackOrCloseWindow}
      />
    )
  }

  if (previousFormSubmissionApprovalStatusError) {
    return (
      <ErrorModal
        error={previousFormSubmissionApprovalStatusError}
        onClose={submissionService.goBackOrCloseWindow}
      />
    )
  }

  if (formNotPublishedError) {
    return (
      <FormUnpublishedModal
        unpublishedUserMessageHtml={formNotPublishedError.message}
      />
    )
  }

  if (preFillDataUnavailableError) {
    return (
      <ErrorModal
        error={preFillDataUnavailableError}
        onClose={submissionService.goBackOrCloseWindow}
      />
    )
  }

  if (draftDataUnavailableError) {
    return (
      <ErrorModal
        error={draftDataUnavailableError}
        onClose={submissionService.goBackOrCloseWindow}
      />
    )
  }

  if (loadError || !form) {
    return (
      <ErrorModal
        error={loadError || new Error('error loading form')}
        onClose={submissionService.goBackOrCloseWindow}
        onSignedUp={refreshForm}
      />
    )
  }

  if (freshdeskAddTicketToNoteError) {
    return (
      <ErrorModal
        error={freshdeskAddTicketToNoteError}
        onClose={submissionService.goBackOrCloseWindow}
      />
    )
  }

  return (
    <section
      className={`section forms-renderer cypress-form-scene oneblink-forms-renderer-styles ${
        form.customCssClasses?.join(' ') ?? ''
      }`}
      id={`form-${form.id}`}
    >
      <div className="container">
        <FormContainer
          formsAppId={formsAppId}
          initialSubmission={initialSubmission}
          draftSubmission={draftSubmission}
          form={form}
          externalId={externalId}
          jobId={jobId}
          preFillFormDataId={preFillFormDataId}
          task={task}
          taskAction={taskAction}
          taskGroup={taskGroup}
          taskGroupInstance={taskGroupInstance}
          isDraftsEnabled={
            isDraftsEnabled &&
            (isUsingFormsKey ? !!form.draftEvents?.length : isAuthorised)
          }
          isUsingFormsKey={isUsingFormsKey}
          previousFormSubmissionApprovalId={previousFormSubmissionApprovalId}
          resumeAtElement={resumeAtElement}
          preFillDataHashId={preFillDataHashId}
          editedFromCpHcmsContentMenuItem={editedFromCpHcmsContentMenuItem}
          pendingTimestamp={pendingTimestamp}
        />
      </div>
      <RefreshSnackbar
        isOpen={needsReload && !swNeedRefresh}
        message="New form content available, please refresh."
        onClick={refreshForm}
      />
    </section>
  )
}

export default React.memo<Props>(FormLoader)
