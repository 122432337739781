import * as React from 'react'
import { Link } from 'react-router-dom'
import { draftService, localisationService } from '@oneblink/apps'

import { Modal, OnLoading } from '..'

import useQuery from 'hooks/useQuery'
import generateResumeDraftUrl from 'services/generate-resume-draft-url'
import useTaskGroupInstanceIdRoute from 'hooks/useTaskGroupInstanceIdRoute'
import { Form } from '@oneblink/types/typescript/forms'
import { getFormIdentifier } from 'services/forms-api-service'

export default function DraftModal({
  draftsForForm,
  onCancel,
  isSyncing,
  generateSearchParams,
  newLinkText = 'New',
  forms,
}: {
  draftsForForm: draftService.LocalFormSubmissionDraft[]
  onCancel: () => void
  isSyncing: boolean
  generateSearchParams?: () => URLSearchParams | undefined
  newLinkText?: string
  forms?: Form[]
}) {
  const { tile } = useQuery()
  const taskGroupInstanceIdRoute = useTaskGroupInstanceIdRoute()

  const draftsWithLinks = React.useMemo(
    () =>
      draftsForForm.reduce<
        Array<
          draftService.LocalFormSubmissionDraft & {
            url: string
          }
        >
      >((memo, draft) => {
        const formIdentifier = getFormIdentifier(draft.formId, forms)
        const url = generateResumeDraftUrl({
          draft,
          parentTile: typeof tile === 'string' ? tile : undefined,
          formIdentifier,
        })
        if (url) {
          memo.push({
            ...draft,
            url,
          })
        }
        return memo
      }, []),
    [draftsForForm, forms, tile],
  )

  const newLink = React.useMemo(() => {
    const firstDraft = draftsWithLinks[0]
    if (!firstDraft) {
      return ''
    }
    const url = new URL(
      `/forms/${getFormIdentifier(firstDraft.formId, forms)}`,
      window.location.origin,
    )
    if (typeof tile === 'string') {
      url.searchParams.append('parentTile', tile)
    }
    if (firstDraft.taskId && firstDraft.taskActionId) {
      url.searchParams.append('taskId', firstDraft.taskId)
      url.searchParams.append('taskActionId', firstDraft.taskActionId)

      if (taskGroupInstanceIdRoute?.params.taskGroupInstanceIdPathParam) {
        url.searchParams.append(
          'taskGroupInstanceId',
          taskGroupInstanceIdRoute?.params.taskGroupInstanceIdPathParam,
        )
      }
    }
    if (generateSearchParams) {
      const extraParams = generateSearchParams()
      extraParams?.forEach((value, key) => url.searchParams.append(key, value))
    }
    return `${url.pathname}${url.search}`
  }, [
    draftsWithLinks,
    taskGroupInstanceIdRoute?.params.taskGroupInstanceIdPathParam,
    tile,
    generateSearchParams,
    forms,
  ])

  if (isSyncing) {
    return (
      <Modal isOpen={isSyncing} actions={null}>
        <div className="cypress-loading has-text-centered">
          <OnLoading className="has-text-centered" />
        </div>
      </Modal>
    )
  }

  if (!draftsWithLinks.length) return null

  return (
    <Modal
      isOpen
      title="Resume Draft"
      className="cypress-drafts-modal"
      actions={
        <>
          <button
            type="button"
            className="button ob-button is-light ob-button__cancel cypress-drafts-modal-cancel-button"
            onClick={onCancel}
          >
            Cancel
          </button>
          <Link
            className="button ob-button is-primary ob-button__new cypress-drafts-modal-new-button"
            to={newLink}
            innerRef={(ref) => {
              ref?.focus()
            }}
          >
            {newLinkText}
          </Link>
        </>
      }
    >
      <p className="has-margin-bottom-6">
        Select a draft to resume or click the <b>{newLinkText}</b> button below
        to start the form.
      </p>

      <div className="ob-list has-dividers has-borders">
        {draftsWithLinks.map((draft) => {
          if (!draft.draftSubmission) return null
          return (
            <Link
              key={draft.url}
              className="ob-list__item is-clickable cypress-draft-list-item"
              to={draft.url}
            >
              <span className="ob-list__content">
                <span className="ob-list__text-primary ob-list__draft-title">
                  {draft.draftSubmission.title}
                </span>
                <span className="ob-list__text-secondary ob-list__draft-timestamp">
                  {localisationService.formatDatetime(
                    new Date(draft.draftSubmission.createdAt),
                  )}
                </span>
              </span>
            </Link>
          )
        })}
      </div>
    </Modal>
  )
}
