import * as React from 'react'
import clsx from 'clsx'
import { useIsOffline, useAuth, useDrafts } from '@oneblink/apps-react'
import { draftService, localisationService } from '@oneblink/apps'
import { SubmissionTypes } from '@oneblink/types'

import { draftsLabel } from 'services/menu-items-service'
import useJobs from 'hooks/useJobs'
import useIsAuthorised from 'hooks/useIsAuthorised'
import {
  RequiresAuthorisationSection,
  ErrorModal,
  OnLoading,
  MaterialIcon,
} from 'components'
import DraftListItem from 'drafts/DraftListItem'
import formsHostnameConfiguration from 'formsHostnameConfiguration'
import useFetchForms from 'hooks/useFetchForms'

function Drafts() {
  const { jobs, reloadJobs } = useJobs()
  const {
    drafts,
    isLoading,
    isSyncing,
    syncDrafts,
    syncError,
    lastSyncTime,
    clearSyncError,
  } = useDrafts()
  const isAuthorised = useIsAuthorised()
  const { isLoggedIn } = useAuth()
  const isOffline = useIsOffline()
  const { forms } = useFetchForms()

  const lastSyncedTime = React.useMemo(() => {
    if (lastSyncTime) {
      return localisationService.formatDatetime(new Date(lastSyncTime))
    }
  }, [lastSyncTime])

  const { draftsWithJobs, isDisplayingJobKeyColumn } = React.useMemo<{
    draftsWithJobs: Array<
      draftService.LocalFormSubmissionDraft & {
        jobData: SubmissionTypes.FormsAppJob | null
      }
    >
    isDisplayingJobKeyColumn: boolean
  }>(() => {
    const associateDraftsWithJobData = drafts.map((draft) => {
      return {
        ...draft,
        jobData: jobs.find((job) => job.id === draft.jobId) || null,
      }
    })

    const isDisplayingJobKeyColumn = associateDraftsWithJobData.some(
      (draft) =>
        draft.jobData && draft.jobData.details && draft.jobData.details.key,
    )

    return {
      draftsWithJobs: associateDraftsWithJobData,
      isDisplayingJobKeyColumn,
    }
  }, [drafts, jobs])

  React.useEffect(() => {
    const abortController = new AbortController()
    if (isAuthorised) {
      reloadJobs()
      syncDrafts(abortController.signal)
    }
    return () => {
      abortController.abort()
    }
  }, [isAuthorised, reloadJobs, syncDrafts])

  return (
    <div className="ob-drafts section is-mobile-section">
      <div className="container">
        <div className="ob-header">
          <h1 className="title is-1 is-size-3-mobile ob-header__heading is-marginless">
            {draftsLabel}
          </h1>

          {isLoggedIn && isAuthorised && !isOffline && (
            <button
              className="button ob-button ob-header__button ob-button__sync is-primary"
              type="button"
              onClick={() => syncDrafts(undefined)}
              disabled={isSyncing}
            >
              <span
                className={clsx('icon', {
                  spinning: isSyncing,
                })}
              >
                <MaterialIcon>sync</MaterialIcon>
              </span>
              <span className="is-hidden-mobile">Sync {draftsLabel}</span>
            </button>
          )}
          {isOffline && (
            <button className="button ob-button ob-header__button is-rounded is-outlined is-warning is-static ob-button__offline">
              <span className="icon">
                <MaterialIcon>wifi_off</MaterialIcon>
              </span>
              <span className="is-hidden-mobile">Offline</span>
            </button>
          )}
        </div>
        {!!lastSyncedTime && (
          <div className="has-margin-bottom-8 time-right" role="status">
            {'Last sync time: ' + lastSyncedTime}
            {!!formsHostnameConfiguration?.draftsAreShared && (
              <> | Shared drafts</>
            )}
          </div>
        )}
        <RequiresAuthorisationSection label={draftsLabel}>
          {isLoading ? (
            <div className="cypress-loading has-text-centered">
              <OnLoading className="has-text-centered" />
              <span>Retrieving {draftsLabel}...</span>
            </div>
          ) : draftsWithJobs.length ? (
            <div className="ob-list has-dividers has-shadow">
              {draftsWithJobs.map((draft) => {
                const formSubmissionDraftId =
                  draft.draftSubmission?.formSubmissionDraftId ||
                  draft.versions?.[0].formSubmissionDraftId
                return (
                  <DraftListItem
                    key={formSubmissionDraftId}
                    formSubmissionDraftId={formSubmissionDraftId}
                    draft={draft}
                    isDisplayingJobKeyColumn={isDisplayingJobKeyColumn}
                    forms={forms}
                  />
                )
              })}
            </div>
          ) : (
            <span className="cypress-no-drafts ob-text__no-drafts">
              You have no {draftsLabel}.
            </span>
          )}
        </RequiresAuthorisationSection>
      </div>

      <ErrorModal error={syncError} onClose={clearSyncError} />
    </div>
  )
}

export default React.memo(Drafts)
