import * as React from 'react'
import { Link } from 'react-router-dom'
import { useDrafts, usePendingSubmissions } from '@oneblink/apps-react'
import clsx from 'clsx'

import { MenuItem } from 'services/menu-items-service'
import useFetchForms from 'hooks/useFetchForms'
import { DraftModal, ErrorModal, MaterialIcon } from 'components'
import useJobs from 'hooks/useJobs'
import formsHostnameConfiguration from 'formsHostnameConfiguration'

const logoUrl = formsHostnameConfiguration?.styles?.logoUrl

function TileContent({
  icon,
  label,
  badge,
}: Pick<MenuItem, 'icon' | 'label' | 'badge'>) {
  return (
    <>
      <div className="ob-tile__icon-container">
        <div>
          <MaterialIcon className="ob-tile__icon">{icon}</MaterialIcon>
        </div>
        {badge && (
          <span className="tag ob-tile__tag is-primary is-rounded">
            {badge}
          </span>
        )}
      </div>
      <div className="ob-tile__label-container">
        <span className="ob-tile__label">{label}</span>
      </div>
    </>
  )
}

function Tile({ classSuffix, href, ...rest }: MenuItem) {
  return (
    <Link className={clsx('ob-tile', classSuffix)} to={href}>
      <TileContent {...rest} />
    </Link>
  )
}

function ContainerTile({ href, label, ...rest }: MenuItem) {
  return (
    <Tile
      {...rest}
      href={`${href}?tile=${encodeURIComponent(label)}`}
      label={label}
    />
  )
}

function FormTile({
  onClick,
  customCssClasses,
  ...rest
}: MenuItem & { onClick: () => void; customCssClasses?: string[] }) {
  return (
    <div
      className={clsx('ob-tile', rest.classSuffix, customCssClasses)}
      onClick={onClick}
      tabIndex={0}
      role="button"
    >
      <TileContent {...rest} />
    </div>
  )
}

function LinkTile({ href, classSuffix, ...rest }: MenuItem) {
  return (
    <a
      href={href}
      className={clsx('ob-tile', classSuffix)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <TileContent {...rest} />
    </a>
  )
}

export default function TileList({ tiles }: { tiles: MenuItem[] }) {
  const { pendingSubmissions } = usePendingSubmissions()
  const { drafts } = useDrafts()
  const { jobs } = useJobs()

  const withoutProfile = React.useMemo(
    () =>
      (tiles || []).reduce<Array<MenuItem>>((memo, tile) => {
        switch (tile.type) {
          case 'PROFILE': {
            break
          }
          case 'PENDING_SUBMISSIONS': {
            memo.push({
              ...tile,
              badge: pendingSubmissions.length || undefined,
            })
            break
          }
          case 'DRAFTS': {
            memo.push({
              ...tile,
              badge: drafts.length || undefined,
            })
            break
          }
          case 'JOBS': {
            memo.push({
              ...tile,
              badge: jobs.length || undefined,
            })
            break
          }
          default:
            memo.push(tile)
        }

        return memo
      }, []),
    [drafts.length, jobs.length, pendingSubmissions.length, tiles],
  )

  const {
    draftsForForm,
    loadError,
    isSyncingAndReloadingDrafts,
    forms,
    actions: { selectForm, deselectForm, clearLoadError },
  } = useFetchForms()

  return (
    <div className="section is-mobile-section">
      <ErrorModal error={loadError} onClose={clearLoadError} />
      <DraftModal
        onCancel={deselectForm}
        draftsForForm={draftsForForm}
        isSyncing={isSyncingAndReloadingDrafts}
        forms={forms}
      />

      <div className="container ob-tile-list">
        {logoUrl && (
          <div className="ob-tile-list__header">
            <img
              src={logoUrl}
              alt="Application Banner Logo"
              className="ob-logo ob-tile-list__logo"
            />
          </div>
        )}
        <div role="navigation" className="ob-tile-list__container">
          {withoutProfile.map((tile) => {
            switch (tile.type) {
              case 'CONTAINER': {
                return <ContainerTile {...tile} key={tile.label} />
              }
              case 'FORM': {
                const form = forms.find((f) => f.id === tile.formId)
                return (
                  <FormTile
                    {...tile}
                    onClick={() => selectForm(tile.formId)}
                    key={tile.label}
                    customCssClasses={form?.customCssClasses}
                  />
                )
              }
              case 'HREF': {
                return <LinkTile {...tile} key={tile.label} />
              }
              default:
                return <Tile {...tile} key={tile.label} />
            }
          })}
        </div>
      </div>
    </div>
  )
}
