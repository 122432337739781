import * as React from 'react'
import { useHistory } from 'react-router-dom'
import * as store from 'local-storage'
import {
  jobService,
  notificationService,
  localisationService,
} from '@oneblink/apps'

import { jobsLabel } from 'services/menu-items-service'
import useJobs, { LocalFormsAppJob } from 'hooks/useJobs'
import useIsAuthorised from 'hooks/useIsAuthorised'
import { OnLoading, RequiresAuthorisationSection, ErrorModal } from 'components'
import { Clickable } from 'components/Clickable'
import useFetchForms from 'hooks/useFetchForms'
import { getFormIdentifier } from 'services/forms-api-service'

function JobsList() {
  const history = useHistory()
  const { jobs, isLoading, loadError, reloadJobs, clearLoadError, formsAppId } =
    useJobs()
  const isAuthorised = useIsAuthorised()
  const { forms } = useFetchForms()

  const openJob = React.useCallback(
    (job: LocalFormsAppJob) => {
      let url = `/forms/${getFormIdentifier(job.formId, forms)}?jobId=${job.id}`
      if (job.externalId) {
        url += `&externalId=${job.externalId}`
      }
      const draft = job.draft
      if (draft?.draftSubmission) {
        console.log('resuming draft for job', job)
        url += `&draftId=${draft.draftSubmission.formSubmissionDraftId}`
      }
      if (job.preFillFormDataId) {
        url += `&preFillFormDataId=${job.preFillFormDataId}`
      }

      history.push(url)
    },
    [forms, history],
  )

  React.useEffect(() => {
    reloadJobs()
  }, [reloadJobs])

  React.useEffect(() => {
    jobService.ensurePrefillFormDataExists(jobs)
  }, [jobs])

  const isDisplayingJobKeyColumn = jobs.some(
    (job) => job.details && job.details.key,
  )

  React.useEffect(() => {
    if (!isAuthorised) {
      return
    }

    const HAS_BEEN_PROMPTED = 'NOTIFICATIONS_SUBSCRIPTION_HAS_BEEN_PROMPTED'

    if (store.get(HAS_BEEN_PROMPTED)) {
      return
    }

    let ignore = false
    notificationService
      .subscribe(formsAppId)
      .catch((error) => {
        console.warn(
          'Error attempting to subscribe to push notifications',
          error,
        )
      })
      .then(() => {
        if (!ignore) {
          store.set(HAS_BEEN_PROMPTED, true)
        }
      })

    return () => {
      ignore = true
    }
  }, [formsAppId, isAuthorised])

  return (
    <div className="ob-jobs section is-mobile-section">
      <div className="container">
        <div className="ob-header has-margin-bottom-4">
          <h1 className="title is-1 is-size-3-mobile ob-header__heading is-marginless">
            {jobsLabel}
          </h1>
        </div>

        <RequiresAuthorisationSection label={jobsLabel}>
          {isLoading ? (
            <div className="cypress-loading has-text-centered">
              <OnLoading className="has-text-centered" />
              <span>Retrieving {jobsLabel}...</span>
            </div>
          ) : jobs.length ? (
            <div className="ob-list has-dividers has-shadow">
              {jobs.map((job) => (
                <Clickable
                  key={job.id}
                  className={`ob-list__item priority-${
                    job.details.priority || 'none'
                  } cypress-job-list-item`}
                  onClick={() => openJob(job)}
                >
                  <div className="ob-list__content-wrapper">
                    {isDisplayingJobKeyColumn && (
                      <div className="ob-list__key ob-list__job-key">
                        {job.details.key}
                      </div>
                    )}
                    <div className="ob-list__content">
                      <div className="ob-list__text-primary ob-list__job-title">
                        {job.details.title}
                      </div>
                      <div className="ob-list__text-secondary ob-list__job-timestamp">
                        Assigned:{' '}
                        {localisationService.formatDatetime(
                          new Date(job.createdAt),
                        )}
                      </div>

                      <div className="ob-list__text-tertiary ob-list__job-form-name">
                        {job.details.description}
                      </div>
                    </div>
                  </div>

                  <div className="ob-list__actions is-block has-text-right">
                    <div className="ob-list__text-primary ob-list__job-type">
                      {job.details.type}
                    </div>
                    {!!job.draft && (
                      <div className="ob-list__job-tag cypress-draft-chip">
                        <span className="tag is-info">Draft</span>
                      </div>
                    )}
                  </div>
                </Clickable>
              ))}
            </div>
          ) : (
            <span className="cypress-no-jobs ob-text__no-jobs">
              You have no {jobsLabel}.
            </span>
          )}
        </RequiresAuthorisationSection>

        <ErrorModal error={loadError} onClose={clearLoadError} />
      </div>
    </div>
  )
}

export default React.memo(JobsList)
