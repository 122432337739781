import * as React from 'react'
import {
  useNullableState,
  useIsMounted,
  useIsOffline,
  useAuth,
  usePendingSubmissions,
} from '@oneblink/apps-react'
import { submissionService } from '@oneblink/apps'
import { useHistory } from 'react-router-dom'

import { pendingSubmissionsLabel } from 'services/menu-items-service'
import { Modal, OnLoading, LoginButton, MaterialIcon } from 'components'
import PendingSubmission from './PendingSubmission'
import { getFormIdentifier } from 'services/forms-api-service'
import useFetchForms from 'hooks/useFetchForms'

function PendingSubmissions() {
  const isMounted = useIsMounted()
  const history = useHistory()
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [hasSeenLoginPrompt, setHasSeenLoginPrompt] = React.useState(false)
  const [pendingTimestampToDelete, attemptDelete, cancelDelete] =
    useNullableState<string>(null)
  const isOffline = useIsOffline()
  const { isLoggedIn } = useAuth()
  const {
    pendingSubmissions,
    isLoading,
    isProcessingPendingQueue,
    processPendingQueue,
    deletePendingSubmission,
  } = usePendingSubmissions()
  const { forms } = useFetchForms()

  const handleDelete = React.useCallback(async () => {
    if (isMounted.current) {
      setIsDeleting(true)
    }

    if (pendingTimestampToDelete) {
      await deletePendingSubmission(pendingTimestampToDelete)
    }

    if (isMounted.current) {
      cancelDelete()
      setIsDeleting(false)
    }
  }, [
    cancelDelete,
    deletePendingSubmission,
    isMounted,
    pendingTimestampToDelete,
  ])

  const handleEdit = React.useCallback(
    async (timestamp: string) => {
      const { preFillFormDataId, formId } =
        await submissionService.editPendingQueueSubmission(timestamp)
      const searchParams = new URLSearchParams()
      searchParams.append('preFillFormDataId', preFillFormDataId)
      searchParams.append('pendingTimestamp', timestamp)
      history.push(
        `/forms/${getFormIdentifier(formId, forms)}?${searchParams.toString()}`,
      )
    },
    [forms, history],
  )

  return (
    <div className="pending-submissions section is-mobile-section">
      <div className="container">
        <div className="ob-header has-margin-bottom-4">
          <h1 className="title is-1 is-size-3-mobile ob-header__heading is-marginless">
            {pendingSubmissionsLabel}
          </h1>
          {!isOffline && !!pendingSubmissions.length && (
            <button
              className="button ob-button ob-header__button ob-button__process is-primary"
              type="button"
              onClick={processPendingQueue}
              disabled={isProcessingPendingQueue}
            >
              {isProcessingPendingQueue ? (
                <span className="icon spinning">
                  <MaterialIcon>cached</MaterialIcon>
                </span>
              ) : (
                <span className="icon">
                  <MaterialIcon>cloud_upload</MaterialIcon>
                </span>
              )}
              <span className="is-hidden-mobile">Process Submissions</span>
            </button>
          )}
          {isOffline && (
            <button className="button ob-button ob-header__button is-rounded is-outlined is-warning is-static ob-button__offline">
              <span className="icon">
                <MaterialIcon>wifi_off</MaterialIcon>
              </span>
              <span className="is-hidden-mobile">Offline</span>
            </button>
          )}
        </div>

        {isLoading && (
          <section>
            <div className="cypress-loading has-text-centered">
              <OnLoading className="has-text-centered" />
              <span>Retrieving Pending Submissions..</span>
            </div>
          </section>
        )}

        {!isLoading && (
          <section>
            {pendingSubmissions.length ? (
              <div className="ob-list has-dividers has-shadow">
                {pendingSubmissions.map((pendingSubmission) => (
                  <PendingSubmission
                    pendingSubmission={pendingSubmission}
                    onDelete={attemptDelete}
                    key={pendingSubmission.pendingTimestamp}
                    onEdit={handleEdit}
                  />
                ))}
              </div>
            ) : (
              <span className="cypress-no-pending-message ob-text__no-submissions">
                You have no pending submissions.
              </span>
            )}
          </section>
        )}
      </div>

      <Modal
        isOpen={!!pendingTimestampToDelete}
        title="Delete Submission"
        actions={
          <>
            <button
              type="button"
              className="button ob-button is-light ob-button__cancel"
              disabled={isDeleting}
              onClick={cancelDelete}
            >
              Cancel
            </button>
            <button
              type="button"
              className="button ob-button is-primary ob-button__delete"
              disabled={isDeleting}
              onClick={handleDelete}
              autoFocus
            >
              Delete
            </button>
          </>
        }
      >
        Do you wish to delete this record?
      </Modal>

      <Modal
        isOpen={
          !hasSeenLoginPrompt &&
          !isLoggedIn &&
          pendingSubmissions.some(
            (submission) => submission.definition.isAuthenticated,
          )
        }
        title="Login Required"
        actions={
          <>
            <button
              type="button"
              className="button ob-button is-light ob-button__cancel"
              onClick={() => setHasSeenLoginPrompt(true)}
            >
              Cancel
            </button>
            <LoginButton autoFocus />
          </>
        }
      >
        You need to login to process your pending submissions that require
        authentication.
      </Modal>
    </div>
  )
}

export default React.memo(PendingSubmissions)
